import { useTranslations } from '~/src/hooks/useTranslations'
import { Button } from '@mui/material'

interface Props {
  onClick(): void,
}

const CheckActivationButton = ({ onClick }: Props) => {
  const { t } = useTranslations()

  return (
    <Button
      variant='contained'
      onClick={ onClick }>
      { t('auth.check_activation.actions.verify' )}
    </Button>
  )
}

export default CheckActivationButton
