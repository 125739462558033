import { SxProps } from '@mui/material'
import StylesActivationServiceInterface
  from '~/src/pages/auth/activation/styles/StylesActivationServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesActivationServiceStyleThree extends TemplateServiceStyleBase implements StylesActivationServiceInterface {
  public getPageSx(): SxProps {
    return {
      my: 4,
      mt: `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(4)})`,
      width: '40%',
      [this.muiTheme.breakpoints.only('xs')]: {
        width: '90%'
      },
      [this.muiTheme.breakpoints.only('sm')]: {
        width: '80%'
      },
      [this.muiTheme.breakpoints.only('md')]: {
        width: '50%'
      }
    }
  }

  public getContentContainerSx(): SxProps {
    return {
      p: 3
    }
  }
}
