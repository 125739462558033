import { Container } from '@mui/material'
import CheckActivation from '~/src/components/auth/activation/CheckActivation'
import useGetActivationTemplate from '~/src/pages/auth/activation/styles/useGetActivationTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const Activation = () => {
  const pageName: keyof typeof RoutesEnum = 'ACCOUNT_ACTIVATION'
  const styles = useGetActivationTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>
      <Container sx={styles.getPageSx()}>
        <CheckActivation/>
      </Container>
    </SlideFromRight>
  )
}

export default Activation
