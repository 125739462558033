import { Box } from '@mui/material'
import CheckActivationButton from '~/src/components/auth/activation/CheckActivationButton'
import CheckActivationRegisterWithOtherButton
  from '~/src/components/auth/activation/CheckActivationRegisterWithOtherButton'

interface Props {
  onCheckActivation(): void,
}

const CheckActivationActions = ({ onCheckActivation }: Props) => (
  <Box
    className='check-activation-actions'
    sx={{
      mt: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      width: '100%'
    }}>
    <CheckActivationButton onClick={onCheckActivation}/>
    <CheckActivationRegisterWithOtherButton/>
  </Box>
)

export default CheckActivationActions
