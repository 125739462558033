import StylesActivationServiceInterface from '~/src/pages/auth/activation/styles/StylesActivationServiceInterface'
import StylesActivationServiceStyleOne from '~/src/pages/auth/activation/styles/StylesActivationServiceStyleOne'
import StylesActivationServiceStyleThree from '~/src/pages/auth/activation/styles/StylesActivationServiceStyleThree'
import StylesActivationServiceStyleTwo from '~/src/pages/auth/activation/styles/StylesActivationServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { LayoutState } from '~/src/stores/layout'

const getActivationTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme, layout: LayoutState): StylesActivationServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesActivationServiceStyleTwo(muiTheme, undefined, layout)
    case TemplateEnum.STYLE_THREE:
      return new StylesActivationServiceStyleThree(muiTheme, undefined, layout)
    default:
      return new StylesActivationServiceStyleOne(muiTheme, undefined, layout)
  }
}

export default getActivationTemplateService
