import { CheckActivationResponse, HttpError, Id } from '@eo-storefronts/eo-core'
import { useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { checkActivation } from '~/src/services/CustomerService'
import { AUTHENTICATION_STATE, IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import useSetAuthState from '~/src/hooks/auth/useSetAuthState'

interface ReturnsType {
  check(displayError: boolean): void,
  activated: boolean,
  error: string | null,
}

const useCheckActivation = (): ReturnsType => {
  const authState = useEoValue(AUTHENTICATION_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const brand = useEoValue(BRAND_STATE)
  const isUsingNewBrandLoginSystem = useEoValue(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)
  const [ error, setError ] = useState<string | null>(null)
  const [ activated, setActivated ] = useState<boolean>(false)
  const { setAuthState } = useSetAuthState()

  const _reset = () => {
    setActivated(false)
    setError(null)
  }
  const _doCheckActivation = (shopId: Id, displayError: boolean) => {
    _reset()

    if (!authState.customer?.id) {
      return
    }

    checkActivation(authState.customer.id, shopId)
      .then((response: CheckActivationResponse) => {
        if (!response) {
          return
        }

        setAuthState({
          guest: undefined,
          customer: {
            id: authState.customer!.id,
            uuid: authState.customer!.uuid,
            token: response.customerToken
          },
          loggedInAs: 'customer'
        })
        setActivated(true)
      })
      .catch((error: HttpError) => {
        if (!displayError) {
          return
        }

        setError(error.message)
      })
  }

  const check = (displayError: boolean) => {
    if (displayError) {
      setError(null)
    }

    if (isUsingNewBrandLoginSystem && brand?.id) {
      _doCheckActivation(brand.id, displayError)
    }

    if (!isUsingNewBrandLoginSystem && firm?.id) {
      _doCheckActivation(firm.id, displayError)
    }
  }

  return { check, activated, error }
}

export default useCheckActivation
