import { useTranslations } from '~/src/hooks/useTranslations'
import { Button } from '@mui/material'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'

const CheckActivationRegisterWithOtherButton = () => {
  const { t } = useTranslations()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()

  const _handleOnClick = () => {
    push(resolve(RoutesEnum.REGISTER))
  }

  return (
    <Button
      variant='outlined'
      onClick={_handleOnClick}>
      {t('auth.check_activation.actions.register_with_an_other_email')}
    </Button>
  )
}

export default CheckActivationRegisterWithOtherButton
